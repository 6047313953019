import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Feedback from "../../components/portal_components/feedback_page/feedback";

const FeedbackPage = () => {
  return (
    <>
      <Sidebar childComponent={<Feedback />} activeText="Feedback" />
    </>
  );
};

export default FeedbackPage;
