import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";

export default function DashboardRowOne(props) {
  const dashboard_data = props.dashboard_data;
  const formatWithCommas = (num) => {
    // Convert the number to a string
    const numStr = String(num);
  
    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = numStr.split(".");
  
    // Reverse the integer part to facilitate comma placement
    let reversedInt = integerPart.split("").reverse().join("");
  
    // Add commas every three digits
    let withCommas = reversedInt.replace(/(\d{3})(?=\d)/g, "$1,");
  
    // Reverse back to the correct order
    integerPart = withCommas.split("").reverse().join("");
  
    // Combine integer and decimal parts
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  };
  return (
    <>
      <p className={styles.row_title}>{props.title}</p>
      <div className={styles.stats_row_one}>
        {dashboard_data.map((data, index) => (
          <div className={styles.top_stat}>
            <div className={styles.top_stat_top}>
              <div className={styles.top_stat_text}>
                <p className={styles.top_stat_text_title}>{formatWithCommas(data.number)}</p>
                <p className={styles.top_stat_text_subtitle}>{data.title}</p>
              </div>
              <div className={styles.mini_bar_chart}>
                <div
                  style={{ height: "10px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "25px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "40px" }}
                  className={styles.top_stat_bar}
                ></div>

                <div
                  style={{ height: "30px" }}
                  className={styles.top_stat_bar}
                ></div>
              </div>
            </div>
            <div className={styles.top_stat_bottom}>
              <div className={styles.top_stat_line}></div>
              <div className={styles.top_stat_bottom_content}>
                <img
                  className={styles.clock_image}
                  src="/images/clock.png"
                  alt="last updated"
                ></img>
                <p className={styles.last_updated}>
                  Updated: {data.last_updated}
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* <div className={styles.top_stat}></div>
      <div className={styles.top_stat}></div>
      <div className={styles.top_stat}></div> */}
      </div>
    </>
  );
}
