const dashboard_data = {
  campaigns_overview_stats: [
    {
      id: "1",
      number: 2500,
      title: "LinkedIn Invites Sent",
      campaign_type: "New Connections Campaign",
      recent_days_data: [10, 25, 40, 30],
      last_updated: "2min ago",
    },
    {
      id: "2",
      number: 620,
      title: "Accepted LinkedIn Invites",
      campaign_type: "New Connections Campaign",
      recent_days_data: [15, 12, 30, 28],
      last_updated: "6min ago",
    },
    {
      id: "3",
      number: 2000,
      title: "New Messages Sent",
      campaign_type: "Existing Connections Campaign",
      recent_days_data: [20, 17, 25, 28],
      last_updated: "36min ago",
    },
    {
      id: "4",
      number: 456,
      title: "New Responses Received",
      campaign_type: "Existing Connections Campaign",
      recent_days_data: [30, 25, 26, 35],
      last_updated: "1min ago",
    },
  ],
  messaging_overview_stats: [
    {
      id: "1",
      number: 1400,
      title: "Messages Sent to Prospects",
      recent_days_data: [10, 25, 40, 30],
      last_updated: "2min ago",
    },
    {
      id: "2",
      number: 950,
      title: "Follow Up Tasks Completed",
      recent_days_data: [10, 25, 40, 30],
      last_updated: "6min ago",
    },
    {
      id: "3",
      number: 92,
      title: "Meeting Links Sent",
      recent_days_data: [10, 25, 40, 30],
      last_updated: "36min ago",
    },
    {
      id: "4",
      number: "64",
      title: "Meetings Scheduled",
      recent_days_data: [10, 25, 40, 30],
      last_updated: "1min ago",
    },
  ],
  upcoming_meetings: [
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imageone.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imagetwo.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imagethree.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imagefour.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imagefive.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
    {
      name: "Alex Hormozi",
      image_link: "/images/DummyProfileImages/imagesix.jpg",
      position: 126,
      email: "alex@hormozi.com",
      call_type: "Introduction Call",
      time: "July 22nd, 3:00 PM CT",
    },
  ],
  recent_activity: [
    {
      id: "1",
      title: "Invite sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "2",
      title: "Invite withdrawn from",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "3",
      title: "Follow up sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "4",
      title: "Response from",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "5",
      title: "Reply sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "6",
      title: "Meeting scheduled with",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
    {
      id: "7",
      title: "Scheduling Link sent to",
      prospect: "John Smith",
      time_stamp: "2min ago",
    },
  ],
};

export default dashboard_data;
