import React from "react";
import styles from "./styles.module.css";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Tooltip } from "@material-ui/core";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#1c1b1b",
    color: "#afafaf",
    fontSize: 10,
  },
}))(Tooltip);

const openPdf = () => {
  window.open("/Documents/ArtistSyncLicensingAgreement.pdf", "_blank");
};

function CheckboxWithLabel(props) {
  const { title, value, action, handler, disabled } = props;

  const handleChange = (event) => {
    handler({ type: action, payload: event.target.checked });
  };

  const renderTitle = () => {
    if (title.includes("United Market’s Sync Pitching Agreement")) {
      const parts = title.split("United Market’s Sync Pitching Agreement");
      return (
        <>
          {capitalizeFirstLetter(parts[0])}
          <span className={styles.agreementText} onClick={openPdf}>
            United Market’s Sync Pitching Agreement
          </span>
          {capitalizeFirstLetter(parts[1])}
        </>
      );
    }
    return capitalizeFirstLetter(title);
  };

  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={handleChange}
      />
      <div className={styles.checkmark}></div>
      <span className={styles.checkbox_text}>{renderTitle()}</span>
    </label>
  );
}

export default CheckboxWithLabel;
