import React from "react";
import { useState } from "react";
import styles from "./feedback.module.css";
import axios from "axios";

const timeStampOne = new Date(Date.now() - 1000000).toISOString();
const timeStampTwo = new Date(Date.now()).toISOString();

function Feedback() {
  const [response, setResponse] = useState("");

  async function TEST_PROSPECT_ENDPOINT() {
    const API_KEY = "RA-a7B4k9F0xZ3yQ1LmP8vTc5S2rHjWnE6o";
    const accountID = "4BvZA0HNH8QmtzqfY2gawAipm7W924";
    const prospectID = "9wLlyv7O7LWFvEOo4BvZsA0HNHqm1";
    // const prospectID = null;
    const request = {
      accountID: accountID,
      prospectID: prospectID,
      messages: [
        {
          sender: accountID,
          content: "Hey! Looking forward to connecting with you!",
          time_stamp: timeStampOne,
        },
        {
          sender: prospectID,
          content: "Hello, glad to connect!",
          time_stamp: timeStampTwo,
        },
      ],
      prospectInfo: {
        name: "James",
        position: "Banked",
      },
    };

    try {
      const response = await axios.post(
        "https://api.staging.replyassist.com/create-prospect",
        request,
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function TEST_CHATBOT_ENDPOINT() {
    const API_KEY = "RA-a7B4k9F0xZ3yQ1LmP8vTc5S2rHjWnE6o";
    const conversationID = "0sHoBxMfhynqRybGdFTL";

    const request = {
      conversationID: conversationID,
      newMessages: [
        {
          sender: conversationID,
          content: "Hey! Looking forward to connecting with you!",
          timestamp: "2023-11-28T12:00:00Z",
        },
        {
          sender: conversationID,
          content: "Hello, glad to connect!",
          timestamp: "2023-11-28T12:00:00Z",
        },
      ],
    };

    try {
      const response = await axios.post(
        "https://api.staging.replyassist.com/chatbot",
        request,
        {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        }
      );
      console.log("Response:", response.data);
      setResponse(response.data.reply);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Feedback</p>
      <textarea className={styles.feedbackBox} value={response}></textarea>
      <div className={styles.buttons}>
        <div className={styles.send_button} onClick={TEST_PROSPECT_ENDPOINT}>
          <p>Test Prospect Endpoint</p>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
