import React from "react";
import styles from "./inbox.module.css";
import { useNavigate } from "react-router-dom";

function formatTimestampToDate(timestamp) {
  try {
    // Convert timestamp to milliseconds if it's not already
    const date = new Date(parseInt(timestamp, 10));

    // Options for toLocaleDateString to get desired format
    const options = { year: "numeric", month: "long", day: "numeric" };

    // Formatting the date
    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error in formatting timestamp:", error);
    return "";
  }
}

const LineDivider = () => {
  return <div className={styles.lineDivider}></div>;
};

const InboxSideProfile = (props) => {
  const profile = props.selectedChat.account;

  const navigate = useNavigate();

  function goToProfile() {
    navigate(`/profile/${profile.uid}`);
  }

  return (
    <div className={styles.side_profile_component}>
      <LineDivider />
      <div className={styles.side_profile_info} onClick={goToProfile}>
        <img
          className={styles.side_profile_image}
          src={profile.profilePicture || "/DefaultAccountImage.png"}
          alt="profile"
        ></img>
        <div className={styles.side_profile_top_info}>
          <p className={styles.side_profile_name}>
            {profile.firstname} {profile.lastname}
          </p>
          <p className={styles.side_profile_subtitle}>{profile.position}</p>
          {profile.biography && (
            <>
              <LineDivider />
              <p className={styles.biography}>{profile.biography}</p>
            </>
          )}
        </div>
        <LineDivider />
        <SideProfileSection connection={props.selectedChat} />
        <div className={styles.bottomlineDivider}></div>
      </div>
      <LineDivider />
    </div>
  );
};

function SideProfileSection(props) {
  return (
    <div className={styles.sideProfileSection}>
      <div className={styles.side_info_title}>Date Connected</div>
      <div className={styles.side_info_subtitle}>
        {formatTimestampToDate(props.connection.dateConnected)}
      </div>
      <div className={styles.side_spacer}></div>
      <div className={styles.side_info_title}>Age</div>
      <div className={styles.side_info_subtitle}>
        {props.connection.account.age}
      </div>
      <div className={styles.side_spacer}></div>
      <div className={styles.side_info_title}>Relationship</div>
      <div className={styles.side_info_subtitle}>
        {props.connection.account.relationship
          ? props.connection.account.relationship
          : "N/A"}
      </div>
    </div>
  );
}

export default InboxSideProfile;
