import React from "react";

import { Sidebar } from "../../layout/Sidebar/Sidebar";
import Dashboard from "../../components/portal_components/dashboard_page/dashboard";

const DashboardPage = () => {
  return (
    <>
      <Sidebar childComponent={<Dashboard />} activeText="Dashboard" />
    </>
  );
};

export default DashboardPage;
