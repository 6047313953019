import React from "react";
import styles from "./universal.module.css";

function LoadingAnimation() {
  return (
    <svg viewBox="25 25 50 50" className={styles.svg_rotate}>
      <circle r="20" cy="50" cx="50"></circle>
    </svg>
  );
}

export default LoadingAnimation;
