import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";
import { ResponsiveBar } from "@nivo/bar";

export default function DashboardRowThree(props) {
  const data = [
    {
      country: "Monday",
      burger: 2,
      color: "#003d82",
    },
    {
      country: "Tuesday",
      burger: 4,
      color: "#003d82",
    },
    {
      country: "Wednesday",
      burger: 3,
      color: "#003d82",
    },
    {
      country: "Thursday",
      burger: 7,
      color: "#003d82",
    },
    {
      country: "Friday",
      burger: 4,
      color: "#003d82",
    },
    {
      country: "Saturday",
      burger: 11,
      color: "#003d82",
    },
    {
      country: "Sunday",
      burger: 2,
      color: "#003d82",
    },
  ];

  return (
    <div className={styles.stats_row_three}>
      <div className={styles.tile}>
        <div className={styles.title_info}>
          <p className={styles.title}>Upcoming Meetings Scheduled</p>
          <p className={styles.subtile}></p>
        </div>
        <div className={styles.tile_scroll}>
          {props.dashboard_data.map((data, index) => (
            <div className={styles.activity_info}>
              <p className={styles.prospect_name}>
                {data.title} {data.prospect}
              </p>
              <p className={styles.prospect_email}>{data.time_stamp}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.title_info}>
          <p className={styles.title}>ReplyAssist Meetings Scheduled</p>
          <p className={styles.subtile}></p>
        </div>
        <div className={styles.pie_chart_box}>
          <ReplyAssistMeetingsScheduled data={data} />
        </div>
      </div>
    </div>
  );
}

const ReplyAssistMeetingsScheduled = ({ data }) => (
  <ResponsiveBar
    data={data}
    colors={({ data }) => data.color}
    keys={["hot dog", "burger", "sandwich", "kebab", "fries", "donut"]}
    indexBy="country"
    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
    padding={0.3}
    valueScale={{ type: "linear" }}
    indexScale={{ type: "band", round: true }}
    // defs={[
    //   // ... your other defs
    //   {
    //     id: "gradient",
    //     type: "linearGradient",
    //     colors: [
    //       { offset: 0, color: "#001f41" },
    //       { offset: 100, color: "#003d82" },
    //     ],
    //   },
    // ]}
    // fill={[
    //   // ... your other fills
    //   {
    //     match: {
    //       id: "burger",
    //     },
    //     id: "gradient",
    //   },
    // ]}
    borderColor={{
      from: "color",
      modifiers: [["darker", 1.6]],
    }}
    axisTop={null}
    axisRight={null}
    // axisBottom={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: "country",
    //   legendPosition: "middle",
    //   legendOffset: 32,
    // }}

    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "Meetings Set",
      legendPosition: "middle",
      legendOffset: -40,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor="white"
    // legends={[
    //   {
    //     dataFrom: "keys",
    //     anchor: "bottom-right",
    //     direction: "column",
    //     justify: false,
    //     translateX: 120,
    //     translateY: 0,
    //     itemsSpacing: 2,
    //     itemWidth: 100,
    //     itemHeight: 20,
    //     itemDirection: "left-to-right",
    //     itemOpacity: 0.85,
    //     symbolSize: 20,
    //     effects: [
    //       {
    //         on: "hover",
    //         style: {
    //           itemOpacity: 1,
    //         },
    //       },
    //     ],
    //   },
    // ]}
    role="application"
    ariaLabel="Nivo bar chart demo"
    barAriaLabel={(e) =>
      e.id + ": " + e.formattedValue + " in country: " + e.indexValue
    }
  />
);
