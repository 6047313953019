import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";

import DashboardRowOne from "./DashboardRow1";
import DashboardRowTwo from "./DashboardRow2";
import DashboardRowThree from "./DashboardRow3";
import DashboardRowFour from "./DashboardRow4";
import dashboard_data from "../../../data/dashboard_data";

export default function Dashboard() {
  return (
    <div className={styles.main_layout}>
      <DashboardRowOne
        dashboard_data={dashboard_data.campaigns_overview_stats}
        title="Campaigns Overview"
      />
      <DashboardRowOne
        dashboard_data={dashboard_data.messaging_overview_stats}
        title="Messaging Overview"
      />
      <DashboardRowTwo dashboard_data={dashboard_data.upcoming_meetings} />
      <DashboardRowThree dashboard_data={dashboard_data.recent_activity} />
      {/* <DashboardRowFour /> */}
    </div>
  );
}
