import { useState, useRef, useEffect } from "react";
import React from "react";
import styles from "./dashboard.module.css";
import { ResponsivePie } from "@nivo/pie";

export default function DashboardRowTwo(props) {
  const dashboard_data = props.dashboard_data;
  const data = [
    {
      id: "High Interest",
      label: "High Interest",
      value: 470,
      color: "rgba(0, 61, 130, 1)",
    },
    {
      id: "Medium Interest",
      label: "Medium Interest",
      value: 496,
      color: "rgba(0, 61, 130, 0.75)",
    },
    {
      id: "Low Interest",
      label: "Low Interest",
      value: 586,
      color: "rgba(0, 61, 130, 0.45)",
    },
    {
      id: "Neutral",
      label: "Neutral",
      value: 184,
      color: "rgba(0, 0, 0, 0.4)",
    },
    {
      id: "Not Interested",
      label: "Not Interested",
      value: 376,
      // color: "rgba(216, 0, 0, 0.75)",
      color: "rgba(0, 0, 0, 0.75)",
    },
  ];

  return (
    <div className={styles.stats_row_two}>
      <div className={styles.tile}>
        <div className={styles.title_info}>
          <p className={styles.title}>Upcoming Meetings Scheduled</p>
          <p className={styles.subtile}></p>
        </div>
        <div className={styles.tile_scroll}>
          {dashboard_data.map((data, index) => (
            <div className={styles.meeting_info_layout}>
              <div className={styles.prospect_info}>
                <img
                  className={styles.profile_image}
                  src={data.image_link}
                  alt="profile"
                ></img>
                <div className={styles.prospect_info_text}>
                  <p className={styles.prospect_name}>{data.name}</p>
                  <p className={styles.prospect_email}>{data.email}</p>
                </div>
              </div>
              <p className={styles.meeting_info}>{data.call_type}</p>
              <p className={styles.meeting_info}>{data.time}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.title_info}>
          <p className={styles.title}>Strength of Pipeline (284 prospects)</p>
          <p className={styles.subtile}></p>
        </div>
        <div className={styles.pie_chart_box}>
          <PipelinePieChart data={data} />
        </div>
      </div>
    </div>
  );
}

const PipelinePieChart = ({ data }) => (
  <ResponsivePie
    data={data}
    colors={({ data }) => data.color}
    margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    borderWidth={1}
    borderColor={{
      from: "color",
      modifiers: [["darker", 0.2]],
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#000000" // Blue color for arc link labels
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#FFFFFF" // Red color for arc labels
    // defs={[
    //   {
    //     id: "dots",
    //     type: "patternDots",
    //     background: "inherit",
    //     color: "rgba(255, 255, 255, 0.3)",
    //     size: 4,
    //     padding: 1,
    //     stagger: true,
    //   },
    //   {
    //     id: "lines",
    //     type: "patternLines",
    //     background: "inherit",
    //     color: "rgba(255, 255, 255, 0.3)",
    //     rotation: -45,
    //     lineWidth: 6,
    //     spacing: 10,
    //   },
    // ]}
    // fill={[
    //   {
    //     match: {
    //       id: "ruby",
    //     },
    //     id: "dots",
    //   },
    //   {
    //     match: {
    //       id: "c",
    //     },
    //     id: "dots",
    //   },
    //   {
    //     match: {
    //       id: "go",
    //     },
    //     id: "dots",
    //   },
    //   {
    //     match: {
    //       id: "python",
    //     },
    //     id: "dots",
    //   },
    //   {
    //     match: {
    //       id: "scala",
    //     },
    //     id: "lines",
    //   },
    //   {
    //     match: {
    //       id: "lisp",
    //     },
    //     id: "lines",
    //   },
    //   {
    //     match: {
    //       id: "elixir",
    //     },
    //     id: "lines",
    //   },
    //   {
    //     match: {
    //       id: "javascript",
    //     },
    //     id: "lines",
    //   },
    // ]}
    // legends={[
    //   {
    //     anchor: "bottom",
    //     direction: "row",
    //     justify: false,
    //     translateX: 0,
    //     translateY: 56,
    //     itemsSpacing: 0,
    //     itemWidth: 100,
    //     itemHeight: 18,
    //     itemTextColor: "#999",
    //     itemDirection: "left-to-right",
    //     itemOpacity: 1,
    //     symbolSize: 18,
    //     symbolShape: "circle",
    //     effects: [
    //       {
    //         on: "hover",
    //         style: {
    //           itemTextColor: "#000",
    //         },
    //       },
    //     ],
    //   },
    // ]}
  />
);
