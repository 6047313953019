import React, { useState, useContext } from "react";
import styles from "./inbox.module.css";
import { ChatContext } from "../../../context/ChatContext";

export default function InboxMessageInput(props) {
  const { sendMessage } = useContext(ChatContext);
  const [message, setMessage] = useState("");
  const selectedChat = props.selectedChat;

  function handleInputChange(event) {
    setMessage(event.target.value);
  }

  function handleSendMessage() {
    if (message.trim() !== "") {
      sendMessage(selectedChat.id, message);
      setMessage("");
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line in the textarea
      handleSendMessage();
    }
  }

  return (
    <div className={styles.message_input_container}>
      <textarea
        className={styles.message_input}
        type="text"
        placeholder="Type message..."
        value={message}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <div className={styles.message_input_controls}>
        <div className={styles.message_input_controls_left}></div>
        <button
          className={styles.send_button_active}
          onClick={handleSendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
}
